<template>
  <div class="home">
    <div class="top">
      <img class="bgTop" :src="BgTop" />
      <div class="tabbar">
        <img class="logo" :src="Logo"></img>
        <div class="list">
          <div v-for="item in menuItems" :key="item.id" class="item" @click="handleItemClick(item.title)">{{ item.title }}</div>
        </div>
        <img class="download" :src="Download"></img>
      </div>
      <div class="smallFlickTV">FlickTV</div>
      <div class="embark">Embark a Visual Journey, 
Anytime, Anywhere</div>
      <img class="phone" :src="Phone">
      <div class="smallFlickTV about">About</div>
      <div class="aboutContent">An app that you can't keep 
your eyes off!Great episodes 
are not to be missed.</div>
      <img class="bigDownload" :src="BigDownload">
      <img class="greenIcon" :src="GreenIcon">
    </div>



    <div  ref="center" class="center">
      <img class="bgCenter" :src="BgCenter">
      <div class="product">Product</div>
      <img class="centerBig" :src="CenterBig">
      <img class="threeImg" :src="ThreeImg">
      <div class="first">
        <div class="title">New Release</div>
        <div class="content">The latest and coolest content recommendations</div>
      </div>
      <div class="second">
        <div class="title">Quick viewing</div>
        <div class="content">Watching several episodes of reeltube in a row.</div>
      </div>
      <div class="bigOne">
        <div class="bigTitle">Mobile Support</div>
        <div class="bigContent" style="width: 35.5vw;">With our reliable developing abilities, we have our lastest verions on both iOS and Android platforms. Coming Soon!</div>
      </div>
      <div class="bigTwo">
        <div class="bigTitle">Rewards</div>
        <div class="bigContent" >On other apps, you can only watch movies by paying. On our app, there will be a reward system. It only takes a little time for you to get the same movie-watching experience</div>
      </div>
      <div class="bigThree">
        <div class="bigTitle">Data Security</div>
        <div class="bigContent">Using the latest ECC(Elliptic Curve Cryptography), the data requested by users is prevented from being tampered. We will not save your data without your permission. Even if you agree, we will use advanced encryption methods to keep your data safe and we will not share your information with any third party.</div>
      </div>
    </div>




    <div  ref="bottom" class="bottom">
      <div class="contact">Contact</div>
      <div class="content">If you ran into any problems ,If you have better suggestions,<br>
if you feel our services is inadequate.</div>
      <div class="content marginTop14">Please contact us:<br>
<span class="address">service@reeltube.cn</span><br>
We will take corresponding measures and give some rewards after verification.</div>

      <div class="copyRight">FlickTV ©2023-2024 www.reeltube.cn</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {
  },
  data () {
    return {
      BgTop: require('../assets/newImgs/bg1.png'),
      BgCenter: require('../assets/newImgs/bg2.png'),
      Logo: require('../assets/newImgs/logo.png'),
      Download: require('../assets/newImgs/download.png'),
      BigDownload: require('../assets/newImgs/bigDownload.png'),
      Phone: require('../assets/newImgs/phone.png'),
      GreenIcon: require('../assets/newImgs/greenIcon.png'),
      ThreeImg: require('../assets/newImgs/threeImg.png'),
      CenterBig: require('../assets/newImgs/centerBig.png'),
      menuItems: [
        { id: 1, title: 'Home' },
        { id: 2, title: 'Product' },
        { id: 3, title: 'Contact' }
      ]
    }
  },
  mounted () {

  },
  computed: {

  },
  methods: {
    handleItemClick (title) {
      if (title == 'Product') {
        this.scrollToCenter()
      } else if (title == 'Contact') {
        this.scrollToBottom()
      }
    },
    scrollToCenter () {
      this.$refs.center.scrollIntoView({ behavior: 'smooth' })
    },
    scrollToBottom () {
      this.$refs.bottom.scrollIntoView({ behavior: 'smooth' })
    }
  },
}
</script>
<style scoped lang="less">
.top {
  width: 100%;
  height: auto;
  position: relative;
  background-color: #1a1a1a;
  .bgTop {
    width: 100%;
    height: auto;
  }
  .tabbar {
    z-index: 999;
    position: absolute;
    left: 13.1944vw;
    top: 2.7778vw;
    display: flex;
    .logo {
      width: 10.625vw;
      height: 2.9167vw;
    }
    .list {
      margin-left: 13.75vw;
      margin-right: 8.5417vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 29.4444vw;
      .item {
        font-size: 0.9028vw;
        font-weight: normal;
        color: #ffffff;
        cursor: pointer;
        user-select: none;
      }
      .item:hover {
        color: #4a4b60;
      }
    }
    .download {
      height: 3.4722vw;
      width: 12.5vw;
      cursor: pointer;
    }
  }
  .smallFlickTV {
    position: absolute;
    height: 1.7361vw;
    display: flex;
    align-items: center;
    padding-left: 1.3889vw;
    font-size: 0.9028vw;
    font-weight: 600;
    color: #ffffff;
    left: 12.4vw;
    top: 15.9722vw;
    border-left: 0.1294vw solid #ffffff;
    opacity: 0.5;
  }
  .embark {
    position: absolute;
    left: 12.4vw;
    top: 18.8194vw;
    font-size: 4.1667vw;
    font-weight: 600;
    line-height: 5.5556vw;
    color: #ffffff;
    width: 62.0833vw;
    user-select: none;
  }
  .phone {
    width: 28.5417vw;
    height: 60.2083vw;
    position: absolute;
    left: 8.9583vw;
    bottom: 2.0139vw;
    z-index: 999;
  }
  .about {
    left: 49.5vw;
    top: 43.0625vw;
    opacity: 1;
  }
  .aboutContent {
    width: 14.1389vw;
    height: 5.625vw;
    opacity: 0.8;
    font-size: 0.9722vw;
    font-weight: normal;
    line-height: 1.875vw;
    color: #ffffff;
    position: absolute;
    left: 51vw;
    top: 46.2778vw;
    user-select: none;
  }
  .bigDownload {
    width: 12.5vw;
    height: 12.5vw;
    position: absolute;
    right: 12.5vw;
    top: 35.3472vw;
    cursor: pointer;
  }
  .greenIcon {
    width: 12.5vw;
    height: 12.5vw;
    position: absolute;
    left: 49.6694vw;
    top: 59.8611vw;
    cursor: pointer;
  }
}
.center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .bgCenter {
    width: 75vw;
  }
  .product {
    position: absolute;
    font-size: 4.1667vw;
    font-weight: 600;
    line-height: 6.9444vw;
    color: #1a1a1a;
    left: 12vw;
    top: 8.3333vw;
  }
  .centerBig {
    position: absolute;
    width: 49.8611vw;
    height: 63.4722vw;
    top: 14.0972vw;
    left: 24.6056vw;
  }
  .threeImg {
    width: 47.9167vw;
    height: 39.8611vw;
    position: absolute;
    bottom: 44.8611vw;
    left: 11.8556vw;
  }
  .first {
    position: absolute;
    left: 11.9956vw;
    top: 68.2639vw;
  }
  .second {
    position: absolute;
    left: 61.9361vw;
    top: 102.1528vw;
  }
  .bigOne {
    position: absolute;
    left: 12.375vw;
    bottom: 25.4861vw;
  }
  .bigTwo {
    position: absolute;
    left: 12.375vw;
    bottom: 7.1528vw;
  }
  .bigThree {
    position: absolute;
    right: 11.775vw;
    bottom: 9.9306vw;
  }
  .title {
    height: 1.7361vw;
    display: flex;
    align-items: center;
    padding-left: 1.3889vw;
    font-size: 0.9028vw;
    font-weight: 600;
    color: #151b26;
    border-left: 0.1794vw solid #151b26;
  }
  .content {
    margin-top: 0.6944vw;
    font-size: 0.9722vw;
    font-weight: normal;
    line-height: 1.875vw;
    color: #7e84a3;
    margin-left: 1.4889vw;
    width: 18.5417vw;
  }
  .bigTitle {
    font-size: 2.4306vw;
    font-weight: 600;
    line-height: 3.125vw;
    color: #1a1a1a;
    user-select: none;
  }
  .bigContent {
    font-size: 1.1806vw;
    font-weight: normal;
    line-height: 2.2222vw;
    margin-top: 0.5vw;
    color: #1a1a1a;
    width: 25vw;
    opacity: 0.6;
    user-select: none;
  }
}
.bottom {
  width: 100%;
  height: 37.7083vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1a1a1a;
  .contact {
    font-family: Poppins;
    font-size: 4.1667vw;
    font-weight: 600;
    line-height: 6.9444vw;
    color: #ffffff;
    margin-top: 5vw;
    user-select: none;
  }
  .content {
    font-size: 1.1806vw;
    font-weight: normal;
    line-height: 2.2222vw;
    letter-spacing: 0.0347vw;
    color: #ffffff;
    text-align: center;
    opacity: 0.6;
    .address {
      color: #88dc13;
    }
  }
  .marginTop14 {
    margin-top: 0.9722vw;
  }
  .copyRight {
    margin-top: 5.3472vw;
    font-size: 1.1806vw;
    font-weight: normal;
    line-height: 2.2222vw;
    text-align: center;
    color: #9e9e9e;
    user-select: none;
    cursor: pointer;
  }
  .copyRight:hover {
    color: #4a4b60;
  }
}
</style>