<template>
  <div id="app">
    <Home></Home>
  </div>
</template>


<script>
import Home from './views/Home.vue'
export default {
  components: {
    Home
  }
}
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

.text {
  font-size: 20px;
}
</style>
