var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"top"},[_c('img',{staticClass:"bgTop",attrs:{"src":_vm.BgTop}}),_c('div',{staticClass:"tabbar"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.Logo}}),_c('div',{staticClass:"list"},_vm._l((_vm.menuItems),function(item){return _c('div',{key:item.id,staticClass:"item",on:{"click":function($event){return _vm.handleItemClick(item.title)}}},[_vm._v(_vm._s(item.title))])}),0),_c('img',{staticClass:"download",attrs:{"src":_vm.Download}})]),_c('div',{staticClass:"smallFlickTV"},[_vm._v("FlickTV")]),_c('div',{staticClass:"embark"},[_vm._v("Embark a Visual Journey, Anytime, Anywhere")]),_c('img',{staticClass:"phone",attrs:{"src":_vm.Phone}}),_c('div',{staticClass:"smallFlickTV about"},[_vm._v("About")]),_c('div',{staticClass:"aboutContent"},[_vm._v("An app that you can't keep your eyes off!Great episodes are not to be missed.")]),_c('img',{staticClass:"bigDownload",attrs:{"src":_vm.BigDownload}}),_c('img',{staticClass:"greenIcon",attrs:{"src":_vm.GreenIcon}})]),_c('div',{ref:"center",staticClass:"center"},[_c('img',{staticClass:"bgCenter",attrs:{"src":_vm.BgCenter}}),_c('div',{staticClass:"product"},[_vm._v("Product")]),_c('img',{staticClass:"centerBig",attrs:{"src":_vm.CenterBig}}),_c('img',{staticClass:"threeImg",attrs:{"src":_vm.ThreeImg}}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)]),_c('div',{ref:"bottom",staticClass:"bottom"},[_c('div',{staticClass:"contact"},[_vm._v("Contact")]),_vm._m(5),_vm._m(6),_c('div',{staticClass:"copyRight"},[_vm._v("FlickTV ©2023-2024 www.reeltube.cn")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"first"},[_c('div',{staticClass:"title"},[_vm._v("New Release")]),_c('div',{staticClass:"content"},[_vm._v("The latest and coolest content recommendations")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second"},[_c('div',{staticClass:"title"},[_vm._v("Quick viewing")]),_c('div',{staticClass:"content"},[_vm._v("Watching several episodes of reeltube in a row.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bigOne"},[_c('div',{staticClass:"bigTitle"},[_vm._v("Mobile Support")]),_c('div',{staticClass:"bigContent",staticStyle:{"width":"35.5vw"}},[_vm._v("With our reliable developing abilities, we have our lastest verions on both iOS and Android platforms. Coming Soon!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bigTwo"},[_c('div',{staticClass:"bigTitle"},[_vm._v("Rewards")]),_c('div',{staticClass:"bigContent"},[_vm._v("On other apps, you can only watch movies by paying. On our app, there will be a reward system. It only takes a little time for you to get the same movie-watching experience")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bigThree"},[_c('div',{staticClass:"bigTitle"},[_vm._v("Data Security")]),_c('div',{staticClass:"bigContent"},[_vm._v("Using the latest ECC(Elliptic Curve Cryptography), the data requested by users is prevented from being tampered. We will not save your data without your permission. Even if you agree, we will use advanced encryption methods to keep your data safe and we will not share your information with any third party.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_vm._v("If you ran into any problems ,If you have better suggestions,"),_c('br'),_vm._v(" if you feel our services is inadequate.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content marginTop14"},[_vm._v("Please contact us:"),_c('br'),_c('span',{staticClass:"address"},[_vm._v("service@reeltube.cn")]),_c('br'),_vm._v(" We will take corresponding measures and give some rewards after verification.")])
}]

export { render, staticRenderFns }